import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import { FaMicrophone } from 'react-icons/fa';
import { useConversation } from 'vocode';

// const HOST = 'localhost:3043';
// // const HOST = 'wss://ringer-ai-backend-vocode-test-test-amd64-rkf6.onrender.com'
// const SOCKET_HOST = `ws://${HOST}`;
// const HTTP_HOST = `http://${HOST}`;

const HOST = process.env.NODE_ENV === 'development' 
  ? 'localhost:3043' 
  : 'ringer-backend-v1.onrender.com';

const SOCKET_HOST = `${process.env.NODE_ENV === 'development' ? 'ws' : 'wss'}://${HOST}`;
const HTTP_HOST = `http://${HOST}`;


const Demo = () => {
  const [backendUrl, setBackendUrl] = useState(`${SOCKET_HOST}/generic_chat`);
  const [selectedOption, setSelectedOption] = useState('generic');
  const [key, setKey] = useState(0);

  const config = {
    backendUrl,
    audioDeviceConfig: {},
  };

  const { status, start, stop, error } = useConversation(config);

  const handleClick = async () => {
    try {
      if (typeof AudioContext !== "undefined") {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        if (audioContext.state === 'suspended') {
          await audioContext.resume();
        }
      }

      if (status === "connected") {
        await stop();
      } else {
        await start();
      }
    } catch (e) {
      console.error("Error starting or stopping conversation:", e);
    }
  };

  useEffect(() => {
      // Update the backendUrl based on the selected option
      if (selectedOption === 'generic') {
        setBackendUrl(`${SOCKET_HOST}/generic_chat`);
      } else {
        setBackendUrl(`${SOCKET_HOST}/appointment_chat`);
      }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption !== 'appointment') return;

    const checkCalendarUpdate = async () => {
      try {
        const response = await fetch(`${HTTP_HOST}/check_calendar_update`);
        if (!response.ok) {
          console.error('Failed to check calendar update');
          return;
        }
        const data = await response.json();
        console.log(`response of /check_calendar_update: ${JSON.stringify(data)}`);
        if (data.updated) {
          setKey(prevKey => prevKey + 1); // Refresh the iframe
      }
      } catch (error) {
        console.error('Error checking calendar update:', error);
      }
    };

    // Poll every 5 seconds (adjust as needed)
    const intervalId = setInterval(checkCalendarUpdate, 5000);

    return () => clearInterval(intervalId);
  }, [selectedOption]);

  // const calendarUrl = 'https://calendar.google.com/calendar/embed?height=600xxxxxx';
  const calendarUrl = 'https://calendar.google.com/calendar/embed?src=f0b43969fa69c3010425ac8cc25d7891bde0711c865d04ce8655371329b93b06%40group.calendar.google.com&ctz=Asia%2FSingapore'

  return (
    <div className="flex flex-col min-h-screen">
      <div className="fixed top-0 left-0 right-0 z-10">
        <NavBar />
      </div>
      
      <div className="flex-grow pt-48 bg-gradient-to-br from-blue-100 to-purple-100 overflow-y-auto">
        <div className="w-full max-w-5xl mx-auto p-4" data-aos="zoom-in">
          <div className="text-center">
            <h1 className="mb-6 text-4xl md:text-5xl lg:text-6xl font-bold text-blue-900">
              Experience AI Agents with Voice
            </h1>
            <div className="text-xl md:text-2xl font-semibold tracking-tight mb-10 text-gray-700">
              Try out from following demo. Click the microphone to start.
            </div>
            <div className="flex flex-col items-center justify-center mb-10">
              <select 
                value={selectedOption} 
                onChange={(e) => setSelectedOption(e.target.value)}
                className="mb-6 p-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="generic">Discuss about life</option>
                <option value="appointment">Book doctor appointment</option>
              </select>
              <button
                onClick={handleClick}
                className={`p-8 bg-white rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${status === 'connected' ? 'bg-red-100' : ''}`}
                disabled={status === "connecting"}
                aria-label={status === 'connected' ? 'Stop recording' : 'Start recording'}
              >
                <FaMicrophone className={`h-16 w-16 ${status === 'connected' ? 'text-red-500 animate-pulse' : 'text-blue-600'} transition-colors duration-300`} />
              </button>
              <p className="mt-4 text-2xl font-semibold text-gray-700">
                {status === 'connected' ? 'Stop' : status === 'connecting' ? 'Connecting...' : 'Start'}
              </p>
              {status === "error" && error && <p className="mt-2 text-red-500">{error.message}</p>}
            </div>
            <div className="mb-10">
              <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center px-8 py-4 text-lg md:text-xl shadow-xl rounded-2xl transition-colors duration-300">
                Book Live Demo
                <svg className="w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </Link>
            </div>
            {selectedOption === 'appointment' && (
              <div className="mt-10">
                <iframe
                  key={key}
                  id="gcal"
                  style={{ border: 'solid 1px #777', width: '100%', height: '600px' }}
                  frameBorder="0"
                  scrolling="no"
                  src={calendarUrl}
                  title="Google Calendar"
                ></iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;